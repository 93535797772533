<template>
    <div v-if="text" class="justify-content-center">
        <terms-conditions-blurb></terms-conditions-blurb>
        <div>
            <div v-if="shouldShowMarketingOptIn">
                <label
                    for="promotionalCheck"
                    style="margin-bottom: 20px; margin-left: 7px">
                    <!-- eslint-disable vue/no-v-html -->
                    <input
                        id="promotionalCheck"
                        v-model="marketingOptIn"
                        type="checkbox" /><span
                        v-html="marketingCheckboxText"></span>
                    <!-- eslint-enable vue/no-v-html -->
                </label>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="terms-agreement text-muted" v-html="termsText"></p>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="shouldShowErrorText"
            class="callout callout-error"
            v-html="submitErrorText" />
        <!-- eslint-enable vue/no-v-html -->
        <div class="row justify-content-center">
            <button
                type="button"
                class="btn"
                :style="cssVars"
                :class="{
                    themed: !isEmbedded && buttonColor,
                    'btn-outline-primary': !isEmbedded,
                    'btn-secondary': isEmbedded,
                }"
                :disabled="isSubmitDisabled"
                @click.stop.prevent="complete()">
                <span v-if="!isSubmitting">{{ text.submit.submitButton }}</span>
                <span v-else class="spinny-span">
                    <clip-loader
                        :loading="true"
                        color="white"
                        size="17px"
                        style="display: inline-block; padding: 0px 30px">
                    </clip-loader>
                </span>
            </button>
        </div>
        <p
            v-if="shouldShowInvalidFormText"
            class="incomplete-form-text text-center error-text">
            {{ text.submit.invalidForm }}
        </p>
        <p v-else class="incomplete-form-text placeholder" />
        <div v-if="shouldShowConfirmation" class="row justify-content-center">
            <p>
                Your transaction confirmation number is
                <strong>{{ transaction.TxId }}</strong
                >. You should receive a confirmation email shortly.
            </p>
        </div>
    </div>
</template>
<script>
import termsConditionsBlurb from "../text/TermsConditionsBlurb.vue"
import clipLoader from "vue-spinner/src/ClipLoader.vue"
import { TraceContext } from "../tracing.js"
import { RecaptchaError } from "../errors/index.js"

/** Valid states the form can be in. */
const FormState = Object.freeze({
    /** User is filling the form in for the first time after loading. */
    Filling: "Filling",

    /**
     * User is correcting errors after attempting to submit at least once.
     *
     * For example, if the user tried to submit, but one or more form fields
     * were invalid, or if it was valid, but the card was declined.
     */
    Correcting: "Correcting",

    /**
     * Form is submitting.
     *
     * TODO: It may be beneficial to break this up into multiple states: Charging, Posting
     */
    Submitting: "Submitting",

    /** Form experienced an error during submission, but may be recovered by the user. */
    Failed: "Failed",

    /**
     * Form experienced an error during submission, and cannot be recovered
     * by the user.
     *
     * Terminal state.
     */
    Crashed: "Crashed",

    /** Form has been submitted successfully. */
    Submitted: "Submitted",
})

/** Actions that the user or application can take. */
const FormEvent = Object.freeze({
    /** User attempted to submit. */
    Completed: "completed",

    /** Form has been validated. */
    Validated: "validated",

    /** Form has been validated. */
    Invalidated: "invalidated",

    /** Form submission has failed, but can recover. */
    Failed: "failed",

    /** Form submission failed and cannot recover. */
    Crashed: "crashed",

    /** Payment data has been successfully charged (for single and monthly
     * gifts) or captured (for AutoGift increases). */
    PaymentConfirmed: "payment-confirmed",

    /** Transaction has been posted to DonorFlow. */
    Posted: "posted",
})

/** Errors that can be thrown during form submission.  */
class FormError extends Error {
    /**
     * @param {string} reason Developer description of the error. May be logged to console.
     * @param {string} code Error code. Should be one of the static codes on this class.
     * @param {string} userMessage Error message to display to the user.
     * @param {boolean} isRecoverable Whether the user can recover from the message or not and should be allowed to resubmit the form.
     * @param  {...any} params
     */
    constructor(reason, code, userMessage, isRecoverable, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(reason, ...params)
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FormError)
        }

        this.name = "FormError"
        // Custom debugging information
        this.reason = reason
        this.code = code
        this.userMessage = userMessage
        this.isRecoverable = isRecoverable
    }

    /** Error code for failure to post to DonorFlow. */
    static DonorFlowError = "donorflow-error"

    /** Error code for reCAPTCHA validation failures. */
    static RecaptchaFail = "recaptcha-fail"

    /** Error code for Stripe errors. */
    static StripeError = "stripe-error"

    /** Catch-all error code. */
    static Unknown = "unknown"
}

export default {
    name: "SubmitSection",
    components: { clipLoader, termsConditionsBlurb },
    data: function () {
        return {
            STRIPE_ACCOUNTS: {
                DONATIONS: "Donations",
            },
            submitting: false,
            cardTypes: {
                visa: "VI",
                mastercard: "MC",
                discover: "DI",
                amex: "AMEX",
            },
            /**
             * A model that represents the valid states and transitions for
             * the form.
             *
             * A _state_ represents mode of the application where
             * the same inputs do different things at some times and not
             * others. (If a button should be sometimes hidden or shown,
             * that should be bound to a state. If a user should be able to
             * do a certain action (like submitting) at some times, but not
             * others, that should be bound to a state.)
             *
             * This state machine manages transitions between different
             * states by sending responses to inputs to different actors,
             * called `actions` here. The actions cannot modify the state
             * directly, but they can @see send based on their own internal
             * processing.
             *
             * This model should help to separate the control flow (managed
             * by the state machine) from the processing details (executed
             * by the actions). Hopefully it also helps by visibly showing  the
             * control flow as opposed to having to trace where multiple
             * booleans are being set.
             *
             * For more context, you can read about state machines and
             * actors, and specifically {@link https://statecharts.dev} and
             * {@link https://xstate.js.org/docs/about/concepts.html}
             */
            stateMachine: {
                /** Current state of the state machine. */
                currentState: FormState.Filling, // Initial state is Filling

                /** For each state, a map of valid events that state can
                 * process and the action that should be taken when that
                 * event is received. */
                states: Object.freeze({
                    [FormState.Filling]: {
                        actions: {
                            [FormEvent.Completed]: () => {
                                this.validate()
                            },
                            [FormEvent.Validated]: () => FormState.Submitting,
                            [FormEvent.Invalidated]: () => FormState.Correcting,
                        },
                    },
                    [FormState.Correcting]: {
                        actions: {
                            [FormEvent.Completed]: () => {
                                this.validate()
                            },
                            [FormEvent.Validated]: () => FormState.Submitting,
                            [FormEvent.Invalidated]: () => FormState.Correcting,
                        },
                    },
                    [FormState.Submitting]: {
                        onEnter: async () => {
                            try {
                                await this.submit()
                            } catch (error) {
                                console.debug(error)
                                // TODO: eventually set submit error.
                                if (error instanceof FormError) {
                                    console.debug(
                                        error.isRecoverable,
                                        error.code,
                                        error.reason,
                                        error.userMessage,
                                    )
                                    if (error.isRecoverable) {
                                        this.stateMachine.send(
                                            FormEvent.Failed,
                                            error.userMessage,
                                        )
                                    } else {
                                        this.stateMachine.send(
                                            FormEvent.Crashed,
                                            error.userMessage,
                                        )
                                    }
                                } else {
                                    return FormState.Crashed
                                }
                            }
                        },
                        actions: {
                            [FormEvent.PaymentDeclined]: (userMessage) => {
                                this.submitErrorText = userMessage
                                return FormState.Correcting
                            },
                            [FormEvent.PaymentConfirmed]: async () => {
                                await this.postTransaction()
                            },
                            [FormEvent.Posted]: () => FormState.Submitted,
                            [FormEvent.Failed]: (userMessage) => {
                                console.error(userMessage)
                                this.submitErrorText = userMessage
                                return FormState.Failed
                            },
                            [FormEvent.Crashed]: (userMessage) => {
                                console.error(userMessage)
                                this.submitErrorText =
                                    userMessage ??
                                    this.text.submitErrorTextDefault
                                return FormState.Crashed
                            },
                        },
                    },
                    [FormState.Failed]: {
                        onEnter: () => {
                            // Immediately transition back to FormState.Correcting.
                            this.validate()
                        },
                        actions: {
                            [FormEvent.Validated]: () => FormState.Correcting,
                            [FormEvent.Invalidated]: () => FormState.Correcting,
                        },
                    },
                    [FormState.Crashed]: {
                        onEnter: async () => {},
                        actions: {
                            [FormEvent.Failed]: () => FormState.Correcting,
                        },
                    },
                    [FormState.Submitted]: {
                        onEnter: async () => {
                            // Marketing data is not critical, so don't fail on error or await it.
                            try {
                                this.pushMarketingData()
                            } catch (error) {
                                console.error(error)
                            }
                            await this.$router.push({
                                name: "confirmation",
                                params: {
                                    id: this.$router.history.current.params.id,
                                },
                            })
                        },
                        actions: {},
                    },
                }),
                /** Method to communicate events to the state machine.
                 *
                 * If the * current state does not know how to handle the event, it
                 * will be dropped. Otherwise, the action will be executed.
                 * If the action returns a @see FormState, the state machine
                 * will transition to that new state and execute the
                 * `onEnter` method for the new state, if any.
                 *
                 * If the state changes while an event is being processed
                 * (e.g. the action `send`s a second event while processing
                 * the first one), the state machine will not transition to
                 * the state returned from the original event's action. This
                 * means that you can safely send() from an actor and expect
                 * that to be applied to the latest state.
                 */
                async send(event, ...args) {
                    // cache current state to compare later.
                    const startingState = this.currentState
                    console.debug(
                        `Processing event '${event}' for state '${this.currentState}'...`,
                    )
                    const stateModel = this.states[this.currentState]
                    const action = (stateModel.actions ?? {})[event]
                    if (!action) {
                        console.debug("no action found. Ignoring.")
                        return
                    }
                    const nextState = await action(...(args ?? []))
                    if (startingState != this.currentState) {
                        console.debug(
                            `State has changed since ${event} was fired from ${startingState} to ${this.currentState}. Not executing action.`,
                        )
                        return
                    }
                    if (!nextState) {
                        console.debug(`Staying in state '${this.currentState}'`)
                        return
                    } else if (!Object.keys(FormState).includes(nextState)) {
                        throw new Error(
                            `Invalid state returned from action ${this.currentState}.${event}`,
                        )
                    } else {
                        console.debug(`Transitioning to state '${nextState}'`)
                        this.currentState = nextState
                        // invoke onEnter() action, if any.
                        const onEnter = this.states[this.currentState].onEnter
                        if (onEnter) await onEnter()
                    }
                },
            },
        }
    },
    computed: {
        /** Google Tag Manager's dataLayer object */
        dataLayer: function () {
            return window.dataLayer
        },
        /** Whether the submission is allowed or not, derived from state machine's current state. */
        isSubmitDisabled: function () {
            return this.isInState([
                FormState.Submitting,
                FormState.Failed,
                FormState.Crashed,
            ])
        },
        /** Whether the form is being submitted, derived from state machine's current state. */
        isSubmitting: function () {
            return this.isInState(FormState.Submitting)
        },
        /** Whether error text should be shown, derived from state machine's current state. */
        shouldShowErrorText: function () {
            return (
                this.isInState([
                    FormState.Correcting,
                    FormState.Failed,
                    FormState.Crashed,
                ]) && this.submitErrorText != null
            )
        },
        /** Whether form validation error text should be shown, derived from state machine's current state. */
        shouldShowInvalidFormText: function () {
            return this.isInState(FormState.Correcting) && this.$v.$anyError
        },
        /** Whether form confirmation text should be shown, derived from the state machine's current state. */
        shouldShowConfirmation: function () {
            return (
                this.isInState(FormState.Submitted) && this.transaction.TxId > 0
            )
        },
        shouldShowMarketingOptIn() {
            const designation = this.$store.state.recipe.codes.designation
            return !designation.toUpperCase().startsWith("K")
        },
        adminView: {
            get() {
                return this.$store.state.adminView
            },
        },
        buttonColor: {
            get() {
                return this.design ? this.design.theme.buttonColor : null
            },
        },
        cssVars: {
            get() {
                return {
                    "--btn-color": !this.isEmbedded ? this.buttonColor : null,
                    "--text-color": this.textColor ? this.textColor : "#fff",
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        isEmbedded() {
            return this.$store.state.isEmbedded
        },
        marketingOptIn: {
            get() {
                return this.$store.state.transaction.Donor.MarketingOptIn
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "MarketingOptIn"],
                    value: value,
                })
            },
        },
        submitDisabled: {
            get() {
                return this.$store.state.submitDisabled
            },
            set(value) {
                this.$store.state.submitDisabled = value
            },
        },
        submitError: {
            get() {
                return this.$store.state.submitError
            },
            set(value) {
                return (this.$store.state.submitError = value)
            },
        },
        submitErrorText: {
            get() {
                return this.$store.state.text[this.$store.getters.formLanguage]
                    .submitErrorText
            },
            set(value) {
                this.$store.state.text[
                    this.$store.getters.formLanguage
                ].submitErrorText = value
            },
        },
        marketingCheckboxText: {
            // Looks up the marketing text based on the TLD.
            get() {
                const domain = window.location.host

                if (domain.endsWith("todayintheword.org")) {
                    return this.text.submit.marketingCheckboxTITW
                } else if (
                    domain.endsWith("moodyradio.org") ||
                    domain.endsWith("radiomoody.org")
                ) {
                    return this.text.submit.marketingCheckboxRadio
                } else {
                    // moodybible.org or moody.edu
                    return this.text.submit.marketingCheckboxBible
                }
            },
        },
        params: {
            get() {
                console.log(this.$route.fullPath)
                return (
                    "?" +
                    [
                        this.$route.fullPath.split("?")[1] ?? "",
                        "form=" + this.$store.state.form.id,
                    ]
                        .filter((e) => e)
                        .join("&")
                )
            },
        },
        termsText: {
            get() {
                if (this.text) {
                    var domain = window.location.host

                    if (
                        domain.includes("moodyradio.org") ||
                        domain.includes("radiomoody.org")
                    ) {
                        domain = "www.moodyradio.org"
                    } else {
                        // edu and titw
                        domain = "www.moodybible.org"
                    }

                    return this.text.submit.privacyAndTerms.replaceAll(
                        "{domain}",
                        domain,
                    )
                } else {
                    return ""
                }
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
        textColor: {
            get() {
                return this.design ? this.design.theme.textColor : null
            },
        },
        transaction: {
            get() {
                return this.$store.state.transaction
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
    },
    watch: {
        adminView: {
            handler: function (value) {
                if (value) this.submitDisabled = true
            },
            immediate: true,
        },
    },
    methods: {
        /** Push marketing data to Google Analytics. */
        async pushMarketingData() {
            // This is not critical, so just swallow errors.
            try {
                const {
                    receiptData: {
                        Amount: amount,
                        campaign,
                        giftType,
                        TxId: txId,
                    },
                } = this.$store.state

                if (this.dataLayer) {
                    // UA version (deprecated)
                    this.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            purchase: {
                                actionField: {
                                    id: txId.toString(),
                                    // 'premium': premium,
                                    revenue: amount,
                                },
                                products: [
                                    {
                                        id: campaign + "-" + giftType,
                                        name: giftType,
                                        category: campaign,
                                        price: amount,
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    })

                    // GA4 data
                    this.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: txId.toString(),
                            affiliation: "",
                            value: amount,
                            currency: "USD",
                            coupon: "",
                            items: [
                                {
                                    item_id: campaign + "-" + giftType,
                                    item_name: giftType,
                                    item_category: campaign,
                                    price: amount,
                                    quantity: 1,
                                },
                            ],
                        },
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },
        /** Detect if current state is one of the states given
         * @param {string[] | string} states
         */
        isInState(states) {
            const currentState = this.stateMachine.currentState
            if (typeof states === "string") return states === currentState
            else if (Array.isArray(states)) return states.includes(currentState)
            else throw new Error("invalid state passed")
        },
        /** Validate all form fields. */
        async validate() {
            this.$v.$touch()
            const event = !this.$v.$anyError
                ? FormEvent.Validated
                : FormEvent.Invalidated
            this.stateMachine.send(event)
        },
        /** Mark the form as complete (from the user's perspective). */
        async complete() {
            return this.stateMachine.send(FormEvent.Completed)
        },
        /**
         * Charge the transaction to Stripe.
         * To post the transaction to DonorFlow, @see postTransaction.
         */
        async submit() {
            let recaptchaToken
            try {
                recaptchaToken = await this.$store.dispatch(
                    "getRecaptchaToken",
                    "givingpage_submit",
                )
            } catch (error) {
                throw new FormError(
                    "Failed to get reCAPTCHA token",
                    FormError.RecaptchaFail,
                    null,
                    false,
                    { cause: error },
                )
            }

            if (this.$store.state.submitError) {
                throw new FormError(
                    "reCAPTCHA validation failed",
                    FormError.RecaptchaFail,
                    this.submitErrorText,
                    false,
                )
            }

            const {
                transaction,
                transaction: {
                    Donor: donor,
                    Payment: { TenderType: tenderType },
                },
                giftType,
            } = this.$store.state

            const customerData = {
                Email: donor.Email,
                Phone: donor.Phone,
                PostalCode: donor.Postal,
                Name: donor.FirstName + " " + donor.LastName,
                AddressLine1: donor.Address1,
                AddressLine2: donor.Address2,
                City: donor.City,
                State: donor.State,
                Country: donor.Country,
            }
            let customerId = null
            // Create customer for AutoGifts
            if (giftType === "monthly" || giftType === "increaseMonthly") {
                const createCustomerRequestData = {
                    CustomerData: customerData,
                    Account: this.STRIPE_ACCOUNTS.DONATIONS,
                    ConstituentId: null,
                    PaymentId: null,
                }
                try {
                    const createCustomerResponse = await this.$store.dispatch(
                        "getOrCreateCustomer",
                        {
                            data: createCustomerRequestData,
                            token: recaptchaToken,
                        },
                    )
                    customerId = createCustomerResponse.customer?.id
                    if (createCustomerResponse.stripeError) {
                        this.stateMachine.send(
                            FormEvent.Crashed,
                            createCustomerResponse.stripeError.message,
                        )
                        throw createCustomerResponse.stripeError
                    } else if (createCustomerResponse.exception) {
                        throw "Unknown error"
                    }
                } catch (customerError) {
                    this.stateMachine.send(FormEvent.Crashed)
                    throw new FormError(
                        "Failed to create Stripe customer for AutoGift.",
                        FormError.StripeError,
                        null,
                        true,
                        { cause: customerError },
                    )
                }
            }

            let paymentData
            try {
                if (giftType === "increaseMonthly") {
                    // Create setup intent with new payment details
                    paymentData = await this.increaseAutogift(
                        customerData,
                        customerId,
                        recaptchaToken,
                    )
                } else {
                    // Charge via payment intent
                    const paymentIntentStub = {
                        Account: this.STRIPE_ACCOUNTS.DONATIONS,
                        CustomerId: customerId,
                        PaymentIntentId: null,
                        CustomerData: customerData,
                        ChargeData: {
                            // Stub does not include Amount, since it comes from either
                            // Stripe Element (cards) or transaction data (bank).
                            Currency: "usd",
                            Description: "",
                            Metadata: null,
                            MandateData: {},
                        },
                        SavePaymentMethod: customerId != null,
                        IsMoto: false,
                        DonationData: transaction,
                    }
                    if (tenderType === "OLP") {
                        paymentData = await this.processStripeCardPayment(
                            paymentIntentStub,
                            customerData,
                            recaptchaToken,
                        )
                    } else if (tenderType === "ECHK") {
                        paymentData = await this.processBankPayment(
                            paymentIntentStub,
                            recaptchaToken,
                        )
                    }
                }
            } catch (chargeError) {
                if (chargeError instanceof RecaptchaError) {
                    const localizationData =
                        this.$store.state.text[this.$store.getters.formLanguage]
                    let userMessage =
                        localizationData.submitErrorBotDetected.replace(
                            "{PARAMS}",
                            this.params,
                        ) +
                        `<p>${
                            localizationData.submit.errorCode
                        }: 99-${chargeError.traceId.substring(3, 7)}</p>`

                    throw new FormError(
                        "reCAPTCHA validation failed.",
                        FormError.RecaptchaFail,
                        userMessage,
                        false,
                        { cause: chargeError },
                    )
                }
                console.error(chargeError)
                // The methods called above should signal
                // FormEvent.PaymentDeclined or FormEvent.Crashed.
                // But in case we didn't handle something we didn't expect,
                // we'll send FormEvent.Crashed again. It won't do anything
                // if the other methods already sent the same event.
                this.stateMachine.send(FormEvent.Crashed)
                return
            }

            // This is just a fallback in case we missed anything.
            // Eventually we should get rid of the state.submitError
            // variable.
            if (this.$store.state.submitError)
                throw new FormError(
                    "There was an error processing the payment.",
                    FormError.Unknown,
                    this.submitErrorText,
                    false,
                )

            // Persist changes to TX
            this.$store.commit("setPaymentData", {
                ...transaction.Payment,
                ...paymentData,
            })

            this.stateMachine.send(FormEvent.PaymentConfirmed)
        },
        /** Post transaction to DonorFlow. */
        async postTransaction() {
            // Posting to DonorFlow is best effort. The transaction has
            // already been charged at this point, so don't throw if
            // DonorFlow fails.
            try {
                await this.$store.dispatch("postDfTransaction")
            } catch {} // eslint-disable-line no-empty
            this.stateMachine.send(FormEvent.Posted)
        },
        /**
         * Process a card payment with Stripe.
         *
         * This will:
         * - create a payment method and payment intent, storing the customer ID and payment method for AutoGifts, and
         * - confirm the payment.
         *
         * Some errors may be logged to the PaymentService payment log.
         */
        async processStripeCardPayment(
            paymentIntentStub,
            customerData,
            recaptchaToken,
        ) {
            const {
                transaction,
                stripePaymentMethod: { amount, cardNumber, stripe },
            } = this.$store.state

            let createPaymentMethodResponse
            try {
                createPaymentMethodResponse = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardNumber,
                    billing_details:
                        this.customerDataToBillingDetails(customerData),
                    metadata: {
                        application: "GappPublic",
                    },
                })
            } catch (createPaymentMethodError) {
                const userMessage =
                    this.text.submitErrorTextDefault +
                    this.text.submitErrorTextCreditCard
                this.stateMachine.send(FormEvent.PaymentDeclined, userMessage)
                throw new FormError(
                    "Failed creating card payment method",
                    FormError.StripeError,
                    userMessage,
                    true,
                )
            }

            // TODO: handle this case better
            const paymentMethod = createPaymentMethodResponse.paymentMethod
            if (!paymentMethod.id) {
                this.stateMachine.send(
                    FormEvent.Crashed,
                    this.text.submitErrorTextDefault,
                )
                throw new FormError(
                    "Payment method id not found after attempting to create a stripe payment method.",
                    FormError.StripeError,
                    "An unknown error occurred while submitting your payment information.",
                    false,
                    { cause: createPaymentMethodResponse },
                )
            }

            const paymentIntentRequest = {
                ...paymentIntentStub,
                PaymentId: paymentMethod.id,
                ChargeData: {
                    ...paymentIntentStub.ChargeData,
                    Amount: amount,
                    PaymentType: "card",
                },
            }

            let errorData, paymentIntentResponse
            try {
                paymentIntentResponse = await this.$store.dispatch(
                    "createOrUpdatePaymentIntent",
                    {
                        data: paymentIntentRequest,
                        token: recaptchaToken,
                    },
                )
            } catch (error) {
                if (error instanceof RecaptchaError) {
                    throw error
                }
                errorData = error
            }

            const { paymentIntent, stripeError: paymentIntentError } =
                paymentIntentResponse
            if (errorData || !paymentIntent || paymentIntentError) {
                let submitErrorData = {}
                const cardExpiryString =
                    String(paymentMethod.card["exp_month"]).padStart(2, "0") +
                    "/" +
                    paymentMethod.card["exp_year"].toString().slice(2)
                const isCardError = paymentIntentError?.type == "card_error"
                if (isCardError) {
                    submitErrorData = {
                        Message: paymentIntentError.message,
                        Vendor: "STRIPE",
                        VendorStatusCode: paymentIntentError.code,
                        VendorRefNum: paymentIntentError.charge,
                        TxData: {
                            ACCT_LAST_FOUR: paymentMethod.card.last4,
                            AMOUNT: amount.toString(),
                            CREDIT_CARD_EXP_DT: cardExpiryString,
                        },
                    }
                } else if (errorData) {
                    submitErrorData = {
                        Message: errorData,
                        Vendor: "STRIPE",
                        VendorStatusCode: null,
                        VendorRefNum: null,
                        TxData: {
                            ACCT_LAST_FOUR: paymentMethod.card.last4,
                            AMOUNT: amount.toString(),
                            CREDIT_CARD_EXP_DT: cardExpiryString,
                        },
                    }
                }
                await this.handleStripeError(submitErrorData)
                throw new FormError(
                    "Could not create payment intent",
                    FormError.StripeError,
                    null,
                    true,
                    { cause: errorData ?? submitErrorData },
                )
            }

            let confirmedCardPayment
            try {
                confirmedCardPayment = await stripe.confirmCardPayment(
                    paymentIntent["client_secret"],
                )
            } catch (confirmError) {
                this.stateMachine.send(
                    FormEvent.Crashed,
                    this.submitErrorTextDefault,
                )
                throw new FormError(
                    "Failed to confirm card payment",
                    FormError.StripeError,
                    this.text.submitErrorTextDefault,
                    false,
                    { cause: confirmError },
                )
            }
            if (confirmedCardPayment.error) {
                this.$store.state.stripeError = confirmedCardPayment.error
                const cardExpiryString =
                    String(paymentMethod.card["exp_month"]).padStart(2, "0") +
                    "/" +
                    paymentMethod.card["exp_year"].toString().slice(2)
                const submitErrorData = {
                    Message: confirmedCardPayment.error.message,
                    Vendor: "STRIPE",
                    VendorStatusCode: confirmedCardPayment.error.code,
                    VendorRefNum: confirmedCardPayment.error.charge,
                    TxData: {
                        ACCT_LAST_FOUR: paymentMethod.card.last4,
                        AMOUNT: this.$store.state.stripePaymentMethod.amount.toString(),
                        CREDIT_CARD_EXP_DT: cardExpiryString,
                        INTV_CD: this.$store.state.recipe.codes.intvCd,
                        MOTIVATION_CD: transaction.Splits[0].Appeal,
                        DESIGNATION: this.$store.state.recipe.codes.designation,
                        GIFT_SOURCE: this.$store.state.recipe.codes.giftSource,
                        UTM_TERM: transaction.Marketing.UtmTerm,
                        UTM_CONTENT: transaction.Marketing.UtmContent,
                        FIRST_NAME: transaction.Donor.FirstName,
                        LAST_NAME: transaction.Donor.LastName,
                        BILLING_COMPANY: transaction.Donor.Organization,
                    },
                }
                await this.handleStripeError(submitErrorData)
                throw confirmedCardPayment.error
            }

            return {
                Vendor: "STRIPE",
                VendorDonorId: paymentIntentStub.CustomerId,
                VendorRefNumber: paymentIntent.id,
                PayToken: paymentMethod.id,
                AcctLastFour: paymentMethod.card.last4,
                TenderType: "OLP",
                // Card-specific fields
                CardType: this.cardTypes[paymentMethod.card.brand],
                CcExpireDate:
                    String(paymentMethod.card["exp_month"]).padStart(2, "0") +
                    "/" +
                    paymentMethod.card["exp_year"].toString().substring(2),
            }
        },
        /**
         * Process an ECHK transaction.
         *
         * This will:
         * - create a payment method and payment intent, and
         * - confirm the payment, storing the customer ID and payment method for AutoGifts.
         *
         * Note: this differs from @see processStripeCardPayment in that
         * account numbers are NOT tokenized, and payments are not confirmed
         * here, as they may be processed asynchronously.
         */
        async processBankPayment(paymentIntentStub, recaptchaToken) {
            const { transaction, bankAccountType } = this.$store.state

            const paymentIntentRequest = {
                ...paymentIntentStub,
                AchData: {
                    AccountNumber: transaction.AccountNumber,
                    RoutingNumber: transaction.Payment.RoutingNumber,
                    IsSavingsAcct: bankAccountType.isSavingsAcct,
                    IsCompanyAcct: bankAccountType.isCompanyAcct,
                },
                ChargeData: {
                    ...paymentIntentStub.ChargeData,
                    Amount: transaction.Splits[0].Amount.toFixed(2),
                    PaymentType: "us_bank_account",
                },
            }

            let paymentIntentResponse
            try {
                paymentIntentResponse = await this.$store.dispatch(
                    "createOrUpdatePaymentIntent",
                    {
                        data: paymentIntentRequest,
                        token: recaptchaToken,
                    },
                )
            } catch (error) {
                if (error instanceof RecaptchaError) {
                    throw error
                }
                // TODO: See if we can detect a decline and send FormEvent.PaymentDeclined instead.
                this.stateMachine.send(FormEvent.Crashed)
                throw new FormError(
                    "Failure creating Stripe payment intent",
                    FormError.StripeError,
                    "There was a problem submitting your gift.",
                    false,
                    { cause: error },
                )
            }

            if (paymentIntentResponse.stripeError) {
                console.error("Error processing bank payment.")
                /*
                    const submitErrorData = {
                        Message: paymentIntentResponse.stripeError.message,
                        Vendor: "STRIPE",
                        VendorStatusCode: paymentIntentResponse.stripeError.code,
                        VendorRefNum: paymentIntentResponse.stripeError.charge,
                        TxData: {
                            "AMOUNT": this.$store.state.transaction.Splits[0].Amount.toFixed(2),
                            "INTV_CD": this.$store.state.recipe.codes.intvCd,
                            "MOTIVATION_CD": this.$store.state.transaction.Splits[0].Appeal,
                            "DESIGNATION": this.$store.state.recipe.codes.designation,
                            "GIFT_SOURCE": this.$store.state.recipe.codes.giftSource,
                            "UTM_TERM": this.$store.state.transaction.Marketing.UtmTerm,
                            "UTM_CONTENT": this.$store.state.transaction.Marketing.UtmContent,
                            "FIRST_NAME": this.$store.state.transaction.Donor.FirstName,
                            "LAST_NAME": this.$store.state.transaction.Donor.LastName,
                            "BILLING_COMPANY": this.$store.state.transaction.Donor.Organization
                        }
                    };
                    */
                await this.handleStripeError()
                throw paymentIntentResponse.stripeError
            }

            const {
                paymentIntent,
                paymentIntent: { payment_method: paymentMethod },
            } = paymentIntentResponse
            return {
                Vendor: "STRIPE",
                VendorDonorId: paymentIntentStub.CustomerId,
                VendorRefNumber: paymentIntent.id,
                PayToken: paymentMethod.id,
                AcctLastFour: paymentMethod["us_bank_account"].last4,
                TenderType: "ECHK",
                // Bank-specific fields
                RoutingNumber:
                    paymentMethod["us_bank_account"]["routing_number"],
            }
        },
        async increaseAutogift(customerData, customerId, recaptchaToken) {
            const {
                transaction,
                recipe,
                stripePaymentMethod: { amount, cardNumber, stripe },
            } = this.$store.state

            let getSetupIntentData = {
                Account: this.STRIPE_ACCOUNTS.DONATIONS,
                CustomerId: customerId,
                CustomerData: customerData,
                DonationData: transaction,
            }
            if (this.$store.state.transaction.Payment.TenderType === "OLP") {
                let paymentMethod
                try {
                    const createPaymentMethodResponse =
                        await stripe.createPaymentMethod({
                            type: "card",
                            card: cardNumber,
                            billing_details:
                                this.customerDataToBillingDetails(customerData),
                            metadata: {
                                application: "GappPublic",
                            },
                        })
                    paymentMethod = createPaymentMethodResponse.paymentMethod
                } catch (createPaymentMethodError) {
                    this.stateMachine.send(FormEvent.Crashed)
                    throw new FormError(
                        "Failed creating a payment method for AutoGift increase",
                        FormError.StripeError,
                        null,
                        false,
                        { cause: createPaymentMethodError },
                    )
                }
                getSetupIntentData.PaymentId = paymentMethod.id

                let setupIntentResponse
                try {
                    setupIntentResponse = await this.$store.dispatch(
                        "getStripeSetupIntent",
                        {
                            data: getSetupIntentData,
                            token: recaptchaToken,
                        },
                    )
                } catch (getSetupIntentError) {
                    if (getSetupIntentError instanceof RecaptchaError) {
                        throw getSetupIntentError
                    }
                    this.stateMachine.send(FormEvent.Crashed)
                    throw new FormError(
                        "Failed retrieving a setup intent for AutoGift increase",
                        FormError.StripeError,
                        null,
                        false,
                        { cause: getSetupIntentError },
                    )
                }
                // TODO: check for .stripeError
                const setupIntent = setupIntentResponse.setupIntent

                let cardSetup
                try {
                    cardSetup = await stripe.confirmCardSetup(
                        setupIntent["client_secret"],
                        { payment_method: paymentMethod.id },
                    )
                } catch (confirmCardError) {
                    this.stateMachine.send(FormEvent.Crashed)
                    throw new FormError(
                        "Failed confirming a card setup for AutoGift increase",
                        FormError.StripeError,
                        null,
                        false,
                        { cause: confirmCardError },
                    )
                }

                if (cardSetup.error) {
                    this.$store.state.stripeError = cardSetup.error
                    const cardExpiryString =
                        String(paymentMethod.card["exp_month"]).padStart(
                            2,
                            "0",
                        ) +
                        "/" +
                        paymentMethod.card["exp_year"].toString().slice(2)
                    const submitErrorData = {
                        Message: cardSetup.error.message,
                        Vendor: "STRIPE",
                        VendorStatusCode: cardSetup.error.code,
                        VendorRefNum: cardSetup.error.charge,
                        TxData: {
                            ACCT_LAST_FOUR: paymentMethod.card.last4,
                            AMOUNT: amount.toString(),
                            CREDIT_CARD_EXP_DT: cardExpiryString,
                            INTV_CD: recipe.codes.intvCd,
                            MOTIVATION_CD: transaction.Splits[0].Appeal,
                            DESIGNATION: recipe.codes.designation,
                            GIFT_SOURCE: recipe.codes.giftSource,
                            UTM_TERM: transaction.Marketing.UtmTerm,
                            UTM_CONTENT: transaction.Marketing.UtmContent,
                            FIRST_NAME: transaction.Donor.FirstName,
                            LAST_NAME: transaction.Donor.LastName,
                            BILLING_COMPANY: transaction.Donor.Organization,
                        },
                    }
                    await this.handleStripeError(submitErrorData)
                    throw cardSetup.error
                }
                return {
                    Vendor: "STRIPE",
                    VendorDonorId: customerId,
                    VendorRefNumber: setupIntent.id,
                    PayToken: paymentMethod.id,
                    AcctLastFour: paymentMethod.card.last4,
                    TenderType: "OLP",
                    // Card-specific fields
                    CardType: this.cardTypes[paymentMethod.card.brand],
                    CcExpireDate:
                        String(paymentMethod.card["exp_month"]).padStart(
                            2,
                            "0",
                        ) +
                        "/" +
                        paymentMethod.card["exp_year"].toString().substring(2),
                }
            } else if (
                this.$store.state.transaction.Payment.TenderType === "ECHK"
            ) {
                getSetupIntentData.AchData = {
                    AccountNumber: transaction.AccountNumber,
                    RoutingNumber: transaction.Payment.RoutingNumber,
                    IsSavingsAcct:
                        this.$store.state.bankAccountType.isSavingsAcct,
                    IsCompanyAcct:
                        this.$store.state.bankAccountType.isCompanyAcct,
                }

                let setupIntentResponse
                try {
                    setupIntentResponse = await this.$store.dispatch(
                        "getStripeSetupIntent",
                        {
                            data: getSetupIntentData,
                            token: recaptchaToken,
                        },
                    )
                } catch (getSetupIntentError) {
                    if (getSetupIntentError instanceof RecaptchaError) {
                        throw getSetupIntentError
                    }
                    this.stateMachine.send(FormEvent.Crashed)
                    throw new FormError(
                        "Failed retrieving a setup intent for AutoGift increase",
                        FormError.StripeError,
                        null,
                        false,
                        { cause: getSetupIntentError },
                    )
                }

                if (setupIntentResponse.stripeError) {
                    /*
                        const submitErrorData = {
                            Message: this.$store.state.stripeSetupIntent.stripeError.message,
                            Vendor: "STRIPE",
                            VendorStatusCode: this.$store.state.stripeSetupIntent.stripeError.code,
                            VendorRefNum: this.$store.state.stripeSetupIntent.stripeError.charge,
                            TxData: {
                                "AMOUNT": this.$store.state.transaction.Splits[0].Amount.toFixed(2),
                                "INTV_CD": this.$store.state.recipe.codes.intvCd,
                                "MOTIVATION_CD": this.$store.state.transaction.Splits[0].Appeal,
                                "DESIGNATION": this.$store.state.recipe.codes.designation,
                                "GIFT_SOURCE": this.$store.state.recipe.codes.giftSource,
                                "UTM_TERM": this.$store.state.transaction.Marketing.UtmTerm,
                                "UTM_CONTENT": this.$store.state.transaction.Marketing.UtmContent,
                                "FIRST_NAME": this.$store.state.transaction.Donor.FirstName,
                                "LAST_NAME": this.$store.state.transaction.Donor.LastName,
                                "BILLING_COMPANY": this.$store.state.transaction.Donor.Organization
                            }
                        };
                        */
                    await this.handleStripeError()
                    throw setupIntentResponse.stripeError
                }
                const {
                    setupIntent,
                    setupIntent: { payment_method: paymentMethod },
                } = setupIntentResponse
                // The payment method might not be returned in the setup
                // intent request, so marking those fields as optional.
                return {
                    Vendor: "STRIPE",
                    VendorDonorId: customerId,
                    VendorRefNumber: setupIntent.id,
                    PayToken: paymentMethod?.id,
                    AcctLastFour: paymentMethod?.["us_bank_account"]?.last4,
                    TenderType: "ECHK",
                    // Bank-specific fields
                    RoutingNumber:
                        paymentMethod?.["us_bank_account"]?.["routing_number"],
                }
            }
        },
        /**
         * Parses Stripe response codes and sends PaymentDeclined message approriately,
         * optionally submitting error data to the payment log.
         */
        async handleStripeError(submitErrorData) {
            if (submitErrorData) {
                const { userMessage } = await this.$store.dispatch(
                    "submitError",
                    submitErrorData,
                )
                this.stateMachine.send(FormEvent.PaymentDeclined, userMessage)
            } else if (
                this.$store.state.transaction.Payment.TenderType === "ECHK"
            ) {
                // TODO: Do we not want to log to PaymentService log for banks?
                // If not, let's leave a comment here so we remember.
                const localResponseCode =
                    this.$store.state.giftType === "increaseMonthly"
                        ? this.$store.state.stripeSetupIntent.localResponseCode
                        : this.$store.state.stripePaymentIntent
                              .localResponseCode
                const localizationData =
                    this.$store.state.text[this.$store.getters.formLanguage]
                let userMessage = localizationData.submitErrorTextDefault
                if (localResponseCode == 1) {
                    userMessage += localizationData.submitErrorTextAch
                }
                userMessage += `<p>${
                    localizationData.submitErrorContactText
                }</p>
                     <p>(${
                         localizationData.submit.errorCode
                     }: ${localResponseCode}-${TraceContext.getRootContext().traceId.substring(
                         0,
                         4,
                     )})</p>`
                this.stateMachine.send(FormEvent.PaymentDeclined, userMessage)
            }
        },
        customerDataToBillingDetails(customerData) {
            return {
                name: customerData.Name,
                email: customerData.Email,
                phone: customerData.Phone,
                address: {
                    city: customerData.City,
                    line1: customerData.AddressLine1,
                    line2: customerData.AddressLine2,
                    postal_code: customerData.PostalCode,
                    state: customerData.State,
                    country: customerData.Country,
                },
            }
        },
    },
}
</script>
<style scoped>
.incomplete-form-text {
    margin-top: 5px;
    font-size: small;
}

.themed.btn-outline-primary {
    color: var(--text-color);
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    opacity: 0.8;
}

.themed.btn-outline-primary:hover {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);
    opacity: 1;
}

.themed.btn-outline-primary.active,
.themed.btn-outline-primary:active {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);
}

.themed.btn-outline-primary:disabled {
    background-color: var(--btn-color);
    color: var(--text-color);
    opacity: 0.4;
}

.themed.btn-outline-primary:disabled:hover {
    cursor: not-allowed;
}

label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
}

input[type="checkbox"] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -4px;
    right: 4px;
    overflow: hidden;
}

.callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-radius: 3px;
    background: #ddd;
}

.callout :deep(h4) {
    font-size: larger;
}

.callout :deep(p:last-of-type) {
    margin-bottom: unset;
}

.callout-error {
    border-left-color: #d9534f;
    background-color: #f8d7da;
}

.callout-error :deep(h4),
.callout-error :deep(a) {
    color: #d9534f;
}

.incomplete-form-text.placeholder:before {
    content: "\200b";
}

.terms-agreement {
    font-size: small;
    text-align: center;
}

.spinny-span {
    position: relative;
    top: 3px;
}
/*IE Defaults*/
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .incomplete-form-text {
        color: red;
    }

    .themed.btn-outline-primary {
        color: #fff;
        border-color: #003b5c;
        background-color: #003b5c;
    }

    .themed.btn-outline-primary:hover {
        color: #fff;
        background-color: #003b5c;
        border-color: #003b5c;
        opacity: 1;
    }

    .themed.btn-outline-primary.active,
    .themed.btn-outline-primary:active {
        color: #fff;
        background-color: #003b5c;
        border-color: #003b5c;
    }

    .themed.btn-outline-primary:disabled {
        background-color: #003b5c;
        color: #fff;
        opacity: 0.4;
    }
}
</style>
