<template>
    <div v-if="text">
        <headingRow :text="text.payment.paymentHeader" size="17px" />
        <button-row
            :options="payMethodOptions"
            :selected-option.sync="payMethod"
            :button-color="buttonColor"
            margin-btm="0" />
        <p
            v-if="$v.payMethod.$dirty && $v.payMethod.$invalid"
            class="error-text text-center">
            {{ text.validation.fieldRequired }}
        </p>
        <p v-else class="error-text placeholder"></p>
        <div v-show="payMethod === 'OLP'" class="row">
            <div class="col-md-6">
                <label for="ccNumber">{{
                    text.payment.fieldHeaders.ccNumber
                }}</label>
                <div class="form-group">
                    <div
                        id="cardNumber"
                        class="form-control stripeCardInput"></div>
                    <div id="card-errors" role="alert"></div>
                    <p
                        v-if="stripeCardNumberError"
                        class="error-text text-right">
                        {{ stripeCardNumberError }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </div>
            <div class="col">
                <label for="exp">{{ text.payment.fieldHeaders.ccExp }}</label>
                <div class="form-group">
                    <div
                        id="cardExpiry"
                        class="form-control stripeCardInput"></div>
                    <p
                        v-if="stripeCardExpiryError"
                        class="error-text text-right">
                        {{ stripeCardExpiryError }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </div>
            <div class="col">
                <label for="cvv">{{ text.payment.fieldHeaders.cvv }}</label>
                <popper
                    trigger="clickToOpen"
                    :options="{ modifiers: { offset: { offset: '0, 5px' } } }">
                    <div class="popper">
                        <div>
                            <img
                                v-show="cardType && cardType == 'AMEX'"
                                id="amexCvvHelp"
                                alt="CCV Help image"
                                title="AMEX CCV Help image"
                                style="padding: 10px"
                                src="https://moodybible.canto.com/direct/image/vkkpnhedkt58940cfgkgs66k4i/z_AG6KImQ1MS99AU2IgbOh54-D0/original?content-type=image%2Fpng&name=amexCvv.png" />
                            <img
                                v-show="!cardType || cardType != 'AMEX'"
                                id="cvvHelp"
                                alt="CCV Help image"
                                title="CCV Help image"
                                style="padding: 10px"
                                src="https://moodybible.canto.com/direct/image/8hpmfo8n0d3t186tnjvlla333n/U77_geUGo9laq0wzw-7vBgd7uts/original?content-type=image%2Fpng&name=nonAmexCvv.png" />
                            <p class="helpInfo">
                                {{
                                    cardType == "AMEX"
                                        ? text.payment.cvvHelpTextAmex
                                        : !cardType
                                          ? text.payment.cvvHelpText
                                          : text.payment.cvvHelpTextNonAmex
                                }}
                            </p>
                        </div>
                    </div>
                    <button slot="reference" class="info-button" tabindex="-1">
                        <svg
                            class="info-icon bi bi-info-circle"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                            <circle cx="8" cy="4.5" r="1" />
                        </svg>
                    </button>
                </popper>
                <div class="form-group">
                    <div
                        id="cardCvc"
                        class="form-control stripeCardInput"></div>
                    <p v-if="stripeCardCvcError" class="error-text text-right">
                        {{ stripeCardCvcError }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </div>
        </div>
        <div
            v-if="payMethod === 'ECHK' && echkDisabled"
            class="row"
            style="text-align: center">
            <div class="col-md-12">
                <span class="text-center"
                    >We are not able to accept bank transfers at this time; please
                    give by credit card or try again later.</span
                ><br />
                <span class="text-center"
                    >We apologize for the inconvenience.</span
                >
            </div>
        </div>
        <div v-else-if="payMethod === 'ECHK'">
            <div class="row">
                <div class="col-md-6">
                    <label for="routingNumber">{{
                        text.payment.fieldHeaders.eftRouting
                    }}</label>
                    <input
                        id="routingNumber"
                        v-model="routingNumber"
                        v-mask="'#########'"
                        class="form-control"
                        @blur="setTouched('routingNumber')" />
                    <p
                        v-if="
                            $v.routingNumber.$dirty &&
                            !$v.routingNumber.required
                        "
                        class="error-text text-right">
                        {{ text.validation.fieldRequired }}
                    </p>
                    <p
                        v-else-if="
                            $v.routingNumber.$dirty && !$v.routingNumber.routing
                        "
                        class="error-text text-right">
                        {{ text.validation.routingNumber }}
                    </p>
                    <p
                        v-else-if="
                            $v.routingNumber.$dirty && $v.routingNumber.$invalid
                        "
                        class="error-text text-right">
                        {{ text.validation.routingLength }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
                <div class="col-md-6">
                    <label for="accountNumber">{{
                        text.payment.fieldHeaders.eftAccount
                    }}</label>
                    <input
                        id="accountNumber"
                        v-model="accountNumber"
                        class="form-control scrub"
                        @blur="setTouched('accountNumber')" />
                    <p
                        v-if="
                            $v.accountNumber.$dirty && $v.accountNumber.$invalid
                        "
                        class="error-text text-right">
                        {{ text.validation.fieldRequired }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="acctType"
                        >What type of bank account is this?</label
                    >
                    <v-select
                        v-model="bankAccountType"
                        class="select"
                        :options="bankAccountTypes"
                        :clearable="false"
                        @blur="setTouched('accountNumber')"></v-select>
                    <p
                        v-if="
                            $v.bankAccountType.$dirty &&
                            $v.bankAccountType.$invalid
                        "
                        class="error-text text-right">
                        {{ text.validation.fieldRequired }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import buttonRow from "../inputs/ButtonRow.vue"
import headingRow from "../text/HeadingRow.vue"
import Popper from "vue-popperjs"
import "vue-popperjs/dist/vue-popper.css"
import VI from "../images/VI.png"
import MC from "../images/MC.png"
import AMEX from "../images/AMEX.png"
import DI from "../images/DI.png"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
    name: "PaymentSection",
    components: { buttonRow, headingRow, popper: Popper, vSelect },
    data: function () {
        return {
            bankAccountTypes: [
                "Personal Checking",
                "Personal Savings",
                "Business Checking",
                "Business Savings",
            ],
            bankAccountEftTypes: {
                "Personal Checking": 2,
                "Personal Savings": 3,
                "Business Checking": 4,
                "Business Savings": 5,
            },
            cardNumberMasked: null,
            //in order to force webpack to pack the images, they have to be imported or used on the page in some way
            cardImageUrls: {
                VI: VI,
                MC: MC,
                AMEX: AMEX,
                DI: DI,
            },
            stripeElements: null,
            stripeCardNumber: null,
            stripeCardNumberError: null,
            stripeCardExpiry: null,
            stripeCardExpiryError: null,
            stripeCardCvc: null,
            stripeCardCvcError: null,
            stripePaymentIntent: null,
            selectedBankAccountType: null,
        }
    },
    computed: {
        accentColor: {
            get() {
                return this.design ? this.design.theme.accentColor : null
            },
        },
        accountNumber: {
            get() {
                return this.$store.state.transaction.AccountNumber
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["AccountNumber"],
                    value: value,
                })
            },
        },
        autoSelectGiftDefault: {
            get() {
                return this.$store.state.recipe.features.autoSelectGiftDefault
            },
        },
        bankAccountType: {
            get() {
                return this.selectedBankAccountType
                    ? this.selectedBankAccountType
                    : ""
            },
            set(value) {
                this.$store.state.bankAccountType.isSavingsAcct =
                    value.includes("Savings")
                this.$store.state.bankAccountType.isCompanyAcct =
                    value.includes("Business")
                const eftAccountTypeVal = {
                    "Personal Checking": 2,
                    "Personal Savings": 3,
                    "Business Checking": 4,
                    "Business Savings": 5,
                }
                this.$store.state.transaction.Payment.EftAccountType =
                    eftAccountTypeVal[value]
                this.selectedBankAccountType = value
            },
        },
        buttonColor: {
            get() {
                return this.design ? this.design.theme.buttonColor : null
            },
        },
        cardImageUrl: {
            get() {
                return this.cardType
                    ? 'url("' + this.cardImageUrls[this.cardType] + '")'
                    : null
            },
        },
        cardType: {
            get() {
                return this.$store.getters.getCardType(false)
            },
        },
        ccMask: {
            get() {
                if (
                    this.$store.state.transaction.CardNumber &&
                    this.$store.state.transaction.CardNumber[0] == "3"
                ) {
                    return "#### ###### #####"
                } else {
                    return "#### #### #### ####"
                }
            },
        },
        cvvMask: {
            get() {
                if (
                    this.$store.state.transaction.CardNumber &&
                    this.$store.state.transaction.CardNumber[0] == "3"
                ) {
                    return "####"
                } else {
                    return "###"
                }
            },
        },
        cardNumber: {
            get() {
                return this.$store.state.transaction.CardNumber
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["CardNumber"],
                    value: value,
                })
            },
        },
        cvv: {
            get() {
                return this.$store.state.transaction.Cvv
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Cvv"],
                    value: value,
                })
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        echkDisabled: {
            get() {
                return this.$store.state.echkDisabled
            },
        },
        expiration: {
            get() {
                return this.$store.state.transaction.Payment.CcExpireDate
            },
            set(value) {
                var temp = value.toString()
                var regex = RegExp("[^0-9/]", "g")
                temp = temp.replace(regex, "")

                if (temp.length == 1 && temp[0] != "1" && temp[0] != "0") {
                    temp = "0" + temp + "/"
                }
                if (temp == "1/") {
                    temp = "01/"
                }
                if (temp.length == 2) {
                    temp = temp + "/"
                }

                this.$store.commit("updateTransaction", {
                    propLocation: ["Payment", "CcExpireDate"],
                    value: temp,
                })
            },
        },
        giftType: {
            get() {
                return this.$store.state.giftType
            },
        },
        payMethodOptions: {
            get() {
                return [
                    { label: this.text.payment.paymentTypes.cc, value: "OLP" },
                    {
                        label: this.text.payment.paymentTypes.eft,
                        value: "ECHK",
                    },
                ]
            },
        },
        payMethod: {
            get() {
                return this.$store.state.transaction.Payment.TenderType
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Payment", "TenderType"],
                    value: value,
                })
            },
        },
        routingNumber: {
            get() {
                return this.$store.state.transaction.Payment.RoutingNumber
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Payment", "RoutingNumber"],
                    value: value,
                })
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
    },
    watch: {
        cardNumberMasked: function () {
            this.cardNumber = this.cardNumberMasked.replace(/\D/g, "")
        },
        "$v.amount.$model": function () {
            if (this.payMethod === "OLP" && this.$v.amount.$model) {
                this.$store.state.stripePaymentMethod.amount =
                    this.$v.amount.$model
                // this.createOrUpdatePaymentIntent();
            }
        },
        "$v.stripeCardNumber.$dirty": function (newValue) {
            if (newValue == true) {
                this.stripeCardNumberError = this.deriveStripeFieldError(
                    this.$v.stripeCardNumber,
                )
            }
        },
        "$v.stripeCardExpiry.$dirty": function (newValue) {
            if (newValue == true) {
                this.stripeCardExpiryError = this.deriveStripeFieldError(
                    this.$v.stripeCardExpiry,
                )
            }
        },
        "$v.stripeCardCvc.$dirty": function (newValue) {
            if (newValue == true) {
                this.stripeCardCvcError = this.deriveStripeFieldError(
                    this.$v.stripeCardCvc,
                )
            }
        },
        payMethod: function () {
            if (this.payMethod === "OLP" && this.$v.amount.$model) {
                this.$store.state.stripePaymentMethod.amount =
                    this.$v.amount.$model
            }
        },
    },
    mounted() {
        this.stripe = window.Stripe(import.meta.env.VITE_STRIPE_KEY, {
            locale:
                this.$store.state.recipe.features.language == "spanish"
                    ? "es-419"
                    : "en",
        })
        this.$store.state.stripePaymentMethod.stripe = this.stripe
        this.stripeElements = this.stripe.elements()
        this.createCardElements()
    },
    beforeMount() {
        if (this.autoSelectGiftDefault) {
            this.$store.commit("updateTransaction", {
                propLocation: ["Payment", "TenderType"],
                value: "OLP",
            })
        }
    },
    methods: {
        createCardElements() {
            const elementStyles = {
                invalid: {
                    color: "#E25950",
                    "::placeholder": {
                        color: "#FFCCA5",
                    },
                },
                base: {
                    "::placeholder": {
                        fontSize: "16px",
                        fontWeight: "400",
                    },
                },
            }
            const elementClasses = {
                focus: "focused",
                empty: "empty",
                invalid: "invalid",
            }

            this.stripeCardNumber = this.stripeElements.create("cardNumber", {
                style: elementStyles,
                classes: elementClasses,
                showIcon: true,
                placeholder: "",
            })
            this.stripeCardNumber.mount("#cardNumber")
            this.$store.state.stripePaymentMethod.cardNumber =
                this.stripeCardNumber
            this.stripeCardNumber.on("change", (event) =>
                this.processElementEvent(
                    this.$v.stripeCardNumber,
                    event,
                    "stripeCardNumberError",
                ),
            )

            this.stripeCardExpiry = this.stripeElements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses,
            })
            this.stripeCardExpiry.mount("#cardExpiry")
            this.$store.state.stripePaymentMethod.cardExpiry =
                this.stripeCardExpiry
            this.stripeCardExpiry.on("change", (event) =>
                this.processElementEvent(
                    this.$v.stripeCardExpiry,
                    event,
                    "stripeCardExpiryError",
                ),
            )

            this.stripeCardCvc = this.stripeElements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses,
                placeholder: "",
            })
            this.stripeCardCvc.mount("#cardCvc")
            this.$store.state.stripePaymentMethod.cardCvc = this.stripeCardCvc
            this.stripeCardCvc.on("change", (event) =>
                this.processElementEvent(
                    this.$v.stripeCardCvc,
                    event,
                    "stripeCardCvcError",
                ),
            )
        },
        async createOrUpdatePaymentIntent() {
            let data = {
                Account: "Donations",
                CustomerId: null,
                PaymentIntentId: null,
                PaymentId: null,
                ChargeData: {
                    Amount: this.$v.amount.$model,
                    Currency: "usd",
                    Description: "",
                    Metadata: {},
                    MandateData: {},
                },
                SavePaymentMethod: false,
                IsMoto: false,
            }
            if (this.$store.state.stripePaymentIntent) {
                data.PaymentIntentId =
                    this.$store.state.stripePaymentIntent.paymentIntent.id
            }
            await this.$store.dispatch("createOrUpdatePaymentIntent", data)
        },
        expirationKeydown(e) {
            if (
                e.key == "Backspace" ||
                e.key == "ArrowLeft" ||
                e.key == "ArrowRight" ||
                e.key == "Tab"
            ) {
                if (
                    e.key == "Backspace" &&
                    this.expiration &&
                    this.expiration.length == 3
                ) {
                    if (this.expiration == "01/") {
                        this.expiration = "0"
                    } else if (this.expiration[0] == "0") {
                        this.expiration = ""
                    } else {
                        this.expiration = "1"
                    }

                    e.preventDefault()
                }

                return
            }

            if (
                /[^0-9/]/g.test(e.key) ||
                (this.expiration &&
                    this.expiration.length == 2 &&
                    e.key != "/") ||
                (this.expiration &&
                    this.expiration.indexOf("/") > -1 &&
                    e.key == "/") ||
                (!this.expiration && e.key == "/") ||
                (this.expiration &&
                    this.expiration.length == 1 &&
                    this.expiration[0] == "0" &&
                    (e.key == "0" || e.key == "/")) ||
                (this.expiration &&
                    this.expiration.length == 1 &&
                    this.expiration[0] == "1" &&
                    e.key != "0" &&
                    e.key != "1" &&
                    e.key != "2" &&
                    e.key != "/") ||
                (this.expiration &&
                    this.expiration.length == 1 &&
                    parseInt(this.expiration[0]) > 1 &&
                    e.key != "/")
            ) {
                e.preventDefault()
            }
        },
        processElementEvent(field, event, error) {
            this.$store.commit("setStripeElementEvent", event)
            field.$touch()
            this[error] = this.deriveStripeFieldError(field)
        },
        deriveStripeFieldError(field) {
            if (field?.$error) {
                if (!field.stripeRequired) {
                    return this.text.validation.fieldRequired
                } else if (!field.stripeComplete) {
                    return field.$model.error?.message
                }
            }
            return null
        },
        setTouched: function (field) {
            this.$v[field].$touch()
        },
    },
}
</script>
<style scoped>
#method-select > .btn.btn-primary,
#method-select > .btn.btn-primary:active,
#method-select > .btn.btn-primary:focus,
#method-select > .btn:focus,
#method-select > .btn:active {
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

/*#method-select > .btn.btn-primary.dimmed {
        color: lightgray;
        background-color: red;
    }*/

#method-select {
    padding-bottom: 0.5em;
}

#cardNumber {
    background-repeat: no-repeat;
    /*background-size: 30px;*/
    background-position: 98% 50%;
}

.buttonIn {
    position: relative;
}

.buttonIn-input {
    width: 100%;
    outline: none;
    border-radius: 5px;
}

.info-button {
    top: 4px;
    border-radius: 5px;
    right: 3px;
    z-index: 2;
    border: none;
    cursor: pointer;
    background-color: #f8f8ff;
}

.info-button:focus {
    border: none;
    box-shadow: none;
    color: #80bdff;
    outline: none;
}

.info-icon {
    width: 1.1em;
    height: 1.1em;
}

.stripeCardInput {
    padding-top: 10px;
}

#exp {
    font-family: sans-serif;
}

/*.buttonIn-button:focus {
        border: none;
        box-shadow: none;
    }*/
</style>
